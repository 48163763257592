import { useEffect, useState } from "react";
import AynaliDolapCanvas from "./newComponents/AynaliDolapCanvas";
import DolapCanvas from "./newComponents/DolapCanvas";
import AynaCanvas from "./newComponents/AynaCanvas";
import CategoriesMenu from "./newComponents/CategoriesMenu";
import { EnumCabinetMirrors } from "./store/cabinetMirror";
import { EnumMirrors, changeLightThickness, changeSelectedExcelItem, setSquareMirrorSelectedItem, changeMirror, changePrivateStatus, changeMenuIsOpen } from "./store/mirrors";
import { setCabinetMirrorName } from "./store/cabinetMirrorFee";
import { useDispatch, useSelector } from "react-redux";
import { EnumCabinets } from "./store/cabinet";
import { setCabinetName } from "./store/cabinetFee";

import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";

export const App = () => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [finalCategory, setFinalCategory] = useState(null);

  const [entegrationSelectedMenu, setEntegrationSelectedMenu] = useState(null);
  const dispatch = useDispatch();

  function getKeyByValue(value) {
    const Enums = [EnumCabinetMirrors, EnumMirrors, EnumCabinets];

    for (let i = 0; i < Enums.length; i++) {
      let indexOfS = Object.values(Enums[i]).indexOf(Number(value));
      let key = Object.keys(Enums[i])[indexOfS];

      if (key) {
        return key;
      }
    }
  }

  const mobileMatches600 = useMediaQuery("(max-width:600px)");

  const { selectedMirror } = useSelector((state) => state.mirror);

  const enumCabinetMirrorNameJsons = [
    {
      name: "PANDORA 1",
      value: EnumCabinetMirrors.PANDORA_1,
      id: EnumCabinetMirrors.PANDORA_1,
      excelName: "Bad Spiegelschrank mit LED Leuchte Pandora 1",
    },
    {
      name: "PANDORA 2",
      value: EnumCabinetMirrors.PANDORA_2,
      id: EnumCabinetMirrors.PANDORA_2,
      excelName: "Spiegelschrank mit Leuchte Pandora und Ablage fürs Bad 2",
    },
    {
      name: "PANDORA 3",
      value: EnumCabinetMirrors.PANDORA_3,
      id: EnumCabinetMirrors.PANDORA_3,
      excelName: "Badezimmerspiegelschrank mit Leuchte Pandora 3",
    },
    {
      name: "PANDORA 4",
      value: EnumCabinetMirrors.PANDORA_4,
      id: EnumCabinetMirrors.PANDORA_4,
      excelName: "Badspiegelschrank mit Fächern und Leuchte Pandora 4",
    },
    {
      name: "KATHERINE 1",
      value: EnumCabinetMirrors.KATHERINE_1,
      id: EnumCabinetMirrors.KATHERINE_1,
      excelName: "Bad Spiegelschrank nach Maß mit LED Lampe Katherine 1",
    },
    {
      name: "KATHERINE 2",
      value: EnumCabinetMirrors.KATHERINE_2,
      id: EnumCabinetMirrors.KATHERINE_2,
      excelName: "Spiegelschrank Bad mit LED Lampe Katherine und Ablagen 2",
    },
    {
      name: "KATHERINE 3",
      value: EnumCabinetMirrors.KATHERINE_3,
      id: EnumCabinetMirrors.KATHERINE_3,
      excelName: "Badezimmerspiegelschrank mit Regalen und Lampe Katherine 3",
    },
    {
      name: "KATHERINE 4",
      value: EnumCabinetMirrors.KATHERINE_4,
      id: EnumCabinetMirrors.KATHERINE_4,
      excelName: "Badspiegelschrank mit Lampe (LED) Katherine und Fächern 4",
    },
    {
      name: "VERONICA 1",
      value: EnumCabinetMirrors.VERONICA_1,
      id: EnumCabinetMirrors.VERONICA_1,
      excelName: "Bad Spiegelschrank mit Lampe (LED) Veronica 1",
    },
    {
      name: "VERONICA 2",
      value: EnumCabinetMirrors.VERONICA_2,
      id: EnumCabinetMirrors.VERONICA_2,
      excelName: "Spiegelschrank mit Lampe Veronica und Ablage fürs Bad 2",
    },
    {
      name: "VERONICA 3",
      value: EnumCabinetMirrors.VERONICA_3,
      id: EnumCabinetMirrors.VERONICA_3,
      excelName: "Badezimmerspiegelschrank mit Lampe Veronica und Regalen 3",
    },
    {
      name: "VERONICA 4",
      value: EnumCabinetMirrors.VERONICA_4,
      id: EnumCabinetMirrors.VERONICA_4,
      excelName: "Badspiegelschrank mit LED Lampe Veronica und Fächern 4",
    },
    {
      name: "OXFORD",
      value: EnumCabinetMirrors.OXFORD,
      id: EnumCabinetMirrors.OXFORD,
      excelName: "Bad Spiegelschrank nach Maß - OXFORD",
    },
    {
      name: "BRISTOL",
      value: EnumCabinetMirrors.BRISTOL,
      id: EnumCabinetMirrors.BRISTOL,
      excelName: "Bad Spiegelschrank - nur INNEN beleuchtet - BRISTOL",
    },
    {
      name: "WESER",
      value: EnumCabinetMirrors.WESER,
      id: EnumCabinetMirrors.WESER,
      excelName: "Badspiegelschrank aus Aluminium - Weser",
    },
    {
      name: "HAMBURG 1",
      value: EnumCabinetMirrors.HAMBURG,
      id: EnumCabinetMirrors.HAMBURG,
      excelName: "BSpiegelschrank mit Leuchtrahmen - Hamburg 1",
    },

    {
      name: "FRANKFURT 1",
      value: EnumCabinetMirrors.FRANKFURT,
      id: EnumCabinetMirrors.FRANKFURT,
      excelName: "Spiegelschrank Frankfurt 1",
    },
    {
      name: "ELBE 1",
      value: EnumCabinetMirrors.ELBE_1,
      id: EnumCabinetMirrors.ELBE_1,
      excelName: "Bad Spiegelschrank aus Aluminium - Elbe 1",
    },
    {
      name: "MUNCHEN 1",
      value: EnumCabinetMirrors.MUNCHEN_1,
      id: EnumCabinetMirrors.MUNCHEN_1,
      excelName: "Spiegelschrank rundum beleuchtet - München 1",
    },
    {
      name: "DRESDEN",
      value: EnumCabinetMirrors.DRESDEN,
      id: EnumCabinetMirrors.DRESDEN,
      excelName: "Spiegelschrank Bad mit LED Licht - DRESDEN",
    },
    {
      name: "LIPPE",
      value: EnumCabinetMirrors.LIPPE,
      id: EnumCabinetMirrors.LIPPE,
      excelName: "Beleuchteter Bad-Spiegelschrank aus Alu - Lippe",
    },
    {
      name: "LECH",
      value: EnumCabinetMirrors.LECH,
      id: EnumCabinetMirrors.LECH,
      excelName: "Alu Spiegelschrank mit Beleuchtung - Lech",
    },
    {
      name: "CHICAGO",
      value: EnumCabinetMirrors.CHICAGO,
      id: EnumCabinetMirrors.CHICAGO,
      excelName: "Spiegelschrank fürs Bad mit LED Licht - CHICAGO",
    },
    {
      name: "COLUMBUS",
      value: EnumCabinetMirrors.COLUMBUS,
      id: EnumCabinetMirrors.COLUMBUS,
      excelName: "Badspiegelschrank nach Maß mit Beleuchtung - COLUMBUS",
    },
    {
      name: "WERRA",
      value: EnumCabinetMirrors.WERRA,
      id: EnumCabinetMirrors.WERRA,
      excelName: "Badspiegelschrank aus Alu beleuchtet - Werra",
    },
    {
      name: "NECKAR",
      value: EnumCabinetMirrors.NECKAR,
      id: EnumCabinetMirrors.NECKAR,
      excelName: "Alu Badspiegelschrank mit Beleuchtung - Neckar",
    },
    {
      name: "HAVEL",
      value: EnumCabinetMirrors.HAVEL,
      id: EnumCabinetMirrors.HAVEL,
      excelName: "Badschrank aus Aluminium mit Spiegel und LED - Havel",
    },
    {
      name: "EL PASO",
      value: EnumCabinetMirrors.EL_PASO,
      id: EnumCabinetMirrors.EL_PASO,
      excelName: "Spiegelschrank für das Bad mit LED - EL PASO",
    },
    {
      name: "DUBLIN",
      value: EnumCabinetMirrors.DUBLIN,
      id: EnumCabinetMirrors.DUBLIN,
      excelName: "LED Spiegelschrank fürs Bad, Maßanfertigung - DUBLIN",
    },
    {
      name: "MUNCHEN 4",
      value: EnumCabinetMirrors.MUNCHEN_4,
      id: EnumCabinetMirrors.MUNCHEN_4,
      excelName: "Spiegelschrank München 4",
    },
    {
      name: "MALAGA",
      value: EnumCabinetMirrors.MALAGA,
      id: EnumCabinetMirrors.MALAGA,
      excelName: "Spiegelschrank - MALAGA",
    },
    {
      name: "SARAGOSSA",
      value: EnumCabinetMirrors.SARAGOSSA,
      id: EnumCabinetMirrors.SARAGOSSA,
      excelName: "LED Spiegelschrank - SARAGOSSA",
    },
    {
      name: "KONSTANZ",
      value: EnumCabinetMirrors.KONSTANZ,
      id: EnumCabinetMirrors.KONSTANZ,
      excelName: "Spiegelschrank Badezimmer beleuchtet - KONSTANZ",
    },
    {
      name: "GRADO",
      value: EnumCabinetMirrors.GRADO,
      id: EnumCabinetMirrors.GRADO,
      excelName: "Bad Spiegelschrank - nur INNEN beleuchtet - GRADO",
    },
    {
      name: "ALMERIA",
      value: EnumCabinetMirrors.ALMERIA,
      id: EnumCabinetMirrors.ALMERIA,
      excelName: "Badezimmer-Spiegelschrank - ALMERIA",
    },
    {
      name: "VILLACH",
      value: EnumCabinetMirrors.VILLACH,
      id: EnumCabinetMirrors.VILLACH,
      excelName: "Bad Spiegelschrank beleuchtet - VILLACH",
    },
    {
      name: "DERBY",
      value: EnumCabinetMirrors.DERBY,
      id: EnumCabinetMirrors.DERBY,
      excelName: "Badezimmerspiegelschrank mit Regalen - DERBY",
    },
    {
      name: "GENT",
      value: EnumCabinetMirrors.GENT,
      id: EnumCabinetMirrors.GENT,
      excelName: "Badspiegelschrank mit Fächern - GENT",
    },
    {
      name: "VENEZIA",
      value: EnumCabinetMirrors.VENEZIA,
      id: EnumCabinetMirrors.VENEZIA,
      excelName: "LED Spiegelschrank - INNEN beleuchtet - VENEZIA",
    },
    {
      name: "BUDAPEST",
      value: EnumCabinetMirrors.BUDAPEST,
      id: EnumCabinetMirrors.BUDAPEST,
      excelName: "Spiegelschrank - BUDAPEST",
    },
    {
      name: "LISSABON",
      value: EnumCabinetMirrors.LISSABON,
      id: EnumCabinetMirrors.LISSABON,
      excelName: "Badspiegelschrank - LISSABON",
    },
    {
      name: "LINZ",
      value: EnumCabinetMirrors.LINZ,
      id: EnumCabinetMirrors.LINZ,
      excelName: "Spiegelschrank Badezimmer - LINZ",
    },
    {
      name: "CORTINA",
      value: EnumCabinetMirrors.CORTINA,
      id: EnumCabinetMirrors.CORTINA,
      excelName: "Spiegelschrank - nur INNEN beleuchtet - CORTINA",
    },
    {
      name: "WINDSOR",
      value: EnumCabinetMirrors.WINDSOR,
      id: EnumCabinetMirrors.WINDSOR,
      excelName: "piegelschrank mit Ablage fürs Bad - WINDSOR",
    },
    {
      name: "MUNCHEN 6",
      value: EnumCabinetMirrors.MUNCHEN_6,
      id: EnumCabinetMirrors.MUNCHEN_6,
      excelName: "Spiegelschrank Bad München 6",
    },
    {
      name: "SANTANDER",
      value: EnumCabinetMirrors.SANTANDER,
      id: EnumCabinetMirrors.SANTANDER,
      excelName: "Bad Spiegelschrank (Unterputz) - SANTANDER",
    },
    {
      name: "MUNCHEN 5",
      value: EnumCabinetMirrors.MUNCHEN_5,
      id: EnumCabinetMirrors.MUNCHEN_5,
      excelName: "LED Bad Spiegelschrank mit Fächern - LAS VEGAS",
    },
    {
      name: "LAS VEGAS",
      value: EnumCabinetMirrors.LAS_VEGAS,
      id: EnumCabinetMirrors.LAS_VEGAS,
      excelName: "Bad Spiegelschrank München 5",
    },
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get("products_id");
    if (productId) {
      let mirrorEnumName = getKeyByValue(productId);

      if (EnumCabinetMirrors[mirrorEnumName]) {
        dispatch(changeMirror(EnumCabinetMirrors[mirrorEnumName]));
        dispatch(setCabinetMirrorName(enumCabinetMirrorNameJsons.find((n) => n.id == productId)?.name || mirrorEnumName)); //TODO EĞER JSONDA YOK İSE ENUM NAME GELSİN DEDİM AMA DOĞRU BİR ÇÖZÜM DEĞİL HER AYNA İÇİN SPİEGELDEKİ İSMİ VERMEMİZ GEREKİYOR
        setEntegrationSelectedMenu(<AynaliDolapCanvas />);
        // setEntegrationSelectedMenu(<Message></Message>);
        dispatch(changePrivateStatus(true));
      }

      if (EnumCabinets[mirrorEnumName]) {
        dispatch(changeMirror(EnumCabinets[mirrorEnumName]));
        dispatch(setCabinetName(enumCabinetMirrorNameJsons.find((n) => n.id == productId)?.name || mirrorEnumName)); //TODO EĞER JSONDA YOK İSE ENUM NAME GELSİN DEDİM AMA DOĞRU BİR ÇÖZÜM DEĞİL HER AYNA İÇİN SPİEGELDEKİ İSMİ VERMEMİZ GEREKİYOR
        setEntegrationSelectedMenu(<DolapCanvas />);
        // setEntegrationSelectedMenu(<Message></Message>);
        dispatch(changePrivateStatus(true));
      }

      if (EnumMirrors[mirrorEnumName]) {
        switch (EnumMirrors[mirrorEnumName]) {
          case EnumMirrors.PLUTO_2:
            dispatch(changeMirror(EnumMirrors.CHARON));
            dispatch(changeLightThickness(0.88));
            dispatch(
              changeSelectedExcelItem({
                name: "Pluto 2",
                id: EnumMirrors.PLUTO_2,
              })
            );
            break;
          case EnumMirrors.CHARON:
            dispatch(changeMirror(EnumMirrors.CHARON));
            dispatch(changeLightThickness(1));
            dispatch(
              changeSelectedExcelItem({
                name: "Charon",
                id: EnumMirrors.CHARON,
              })
            );
            break;
          case EnumMirrors.HELENA:
            dispatch(changeMirror(EnumMirrors.CHARON));
            dispatch(changeLightThickness(0.97));
            dispatch(
              changeSelectedExcelItem({
                name: "Helena",
                id: EnumMirrors.HELENA,
              })
            );
            break;
          case EnumMirrors.DIANA:
            dispatch(changeMirror(EnumMirrors.CHARON));
            dispatch(changeLightThickness(0.9));
            dispatch(
              changeSelectedExcelItem({
                name: "Diana",
                id: EnumMirrors.DIANA,
              })
            );
            break;
          case EnumMirrors.RAHMENLOSER_6_MM:
            dispatch(changeMirror(EnumMirrors.RAHMENLOSER_6_MM));
            dispatch(setSquareMirrorSelectedItem("Kristallspiegel"));
            dispatch(
              changeSelectedExcelItem({
                name: "RAHMENLOSER 6 MM",
                id: EnumMirrors.RAHMENLOSER_6_MM,
              })
            );
            break;
          case EnumMirrors.STARK_RAHMENLOS_5MM:
            dispatch(changeMirror(EnumMirrors.STARK_RAHMENLOS_5MM));
            dispatch(setSquareMirrorSelectedItem("Kristallspiegel"));
            dispatch(
              changeSelectedExcelItem({
                name: "STARK RAHMENLOS 5MM",
                id: EnumMirrors.STARK_RAHMENLOS_5MM,
              })
            );
            break;
          case EnumMirrors.SILBER_RAHMENLOS_3MM:
            dispatch(changeMirror(EnumMirrors.SILBER_RAHMENLOS_3MM));
            dispatch(setSquareMirrorSelectedItem("Kristallspiegel"));
            dispatch(
              changeSelectedExcelItem({
                name: "SILBER RAHMENLOS 3MM",
                id: EnumMirrors.SILBER_RAHMENLOS_3MM,
              })
            );
            break;
          case EnumMirrors.NEW_JERSEY:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Arka"));
            dispatch(
              changeSelectedExcelItem({
                name: "New Jersey",
                id: EnumMirrors.NEW_JERSEY,
              })
            );
            break;
          case EnumMirrors.NEW_YORK:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Full_+_Dış"));
            dispatch(
              changeSelectedExcelItem({
                name: "New York",
                id: EnumMirrors.NEW_YORK,
              })
            );
            break;
          case EnumMirrors.BOLNUEVO:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Full_+_İnce"));
            dispatch(
              changeSelectedExcelItem({
                name: "Bolnuevo",
                id: EnumMirrors.BOLNUEVO,
              })
            );
            break;
          case EnumMirrors.PORTAU:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Full_+_Kalın"));
            dispatch(
              changeSelectedExcelItem({
                name: "Portau",
                id: EnumMirrors.PORTAU,
              })
            );
            break;
          case EnumMirrors.NAMUS:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Üst"));
            dispatch(
              changeSelectedExcelItem({
                name: "Namus",
                id: EnumMirrors.NAMUS,
              })
            );
            break;
          case EnumMirrors.M18L2V:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Sağ_+_Sol_+_Uzun"));
            dispatch(
              changeSelectedExcelItem({
                name: "M18L2V",
                id: EnumMirrors.M18L2V,
              })
            );
            break;
          case EnumMirrors.MIAMI:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Alt_+_Üst"));
            dispatch(
              changeSelectedExcelItem({
                name: "Miami",
                id: EnumMirrors.MIAMI,
              })
            );
            break;
          case EnumMirrors.M01L2V:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Sağ_+_Sol"));
            dispatch(
              changeSelectedExcelItem({
                name: "M01L2V",
                id: EnumMirrors.M01L2V,
              })
            );
            break;
          case EnumMirrors.SANTA_CRUZ:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Sağ_+_Sol_+_Üst_+_Dış"));
            dispatch(
              changeSelectedExcelItem({
                name: "Santa Cruz",
                id: EnumMirrors.SANTA_CRUZ,
              })
            );
            break;
          case EnumMirrors.GREEN_BAY:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Sağ_+_Üst_+_Sol"));
            dispatch(
              changeSelectedExcelItem({
                name: "Green Bay",
                id: EnumMirrors.GREEN_BAY,
              })
            );
            break;
          case EnumMirrors.AUROA:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Full_+_İnce_+_İç"));
            dispatch(
              changeSelectedExcelItem({
                name: "Aurora",
                id: EnumMirrors.AUROA,
              })
            );
            break;
          case EnumMirrors.F620TL:
            dispatch(changeMirror(EnumMirrors.F620TL));
            dispatch(
              changeSelectedExcelItem({
                name: "F620TL",
                id: EnumMirrors.F620TL,
              })
            );
            break;
          case EnumMirrors.F621TL:
            dispatch(changeMirror(EnumMirrors.F621TL));
            dispatch(
              changeSelectedExcelItem({
                name: "F621TL",
                id: EnumMirrors.F621TL,
              })
            );
            break;
          case EnumMirrors.F622TL:
            dispatch(changeMirror(EnumMirrors.F622TL));
            dispatch(
              changeSelectedExcelItem({
                name: "F622TL",
                id: EnumMirrors.F622TL,
              })
            );
            break;
          case EnumMirrors.F623TL:
            dispatch(changeMirror(EnumMirrors.F623TL));
            dispatch(
              changeSelectedExcelItem({
                name: "F623TL",
                id: EnumMirrors.F623TL,
              })
            );
            break;
          case EnumMirrors.F610L4K:
            dispatch(changeMirror(EnumMirrors.F610L4K));
            dispatch(
              changeSelectedExcelItem({
                name: "F610L4K",
                id: EnumMirrors.F610L4K,
              })
            );
            break;
          case EnumMirrors.F597L4K:
            dispatch(changeMirror(EnumMirrors.F597L4K));
            dispatch(
              changeSelectedExcelItem({
                name: "F597L4K",
                id: EnumMirrors.F597L4K,
              })
            );
            break;
          case EnumMirrors.WUNSCHDEKOR:
            dispatch(changeMirror(EnumMirrors.WUNSCHDEKOR));
            dispatch(
              changeSelectedExcelItem({
                name: "WUNSCHDEKOR",
                id: EnumMirrors.WUNSCHDEKOR,
              })
            );
            break;
        }
        setEntegrationSelectedMenu(<AynaCanvas />);
        dispatch(changePrivateStatus(true));
      }
    } else {
      dispatch(changePrivateStatus(false));
    }
  }, []);

  useEffect(() => {
    if (mobileMatches600) {
      dispatch(changeMenuIsOpen(true));
    }
  }, [selectedMirror]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0d646f",
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },
  });

  const Message = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            color: "#117A88",
            fontSize: 20,
          }}
        >
          Coming Soon
        </p>
      </div>
    );
  };

  const [testEdeceginYer, setTest] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("https://alcanconsulting.com/konfigurator/");
      const data = await response.json();
      if (data.success) {
        setTest(true);
      } else {
        setTest(false);
      }
    };

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div id="masterContainerDiv" className="w-full  h-100vh flex">
        {testEdeceginYer ? (
          entegrationSelectedMenu == null ? (
            <>
              <CategoriesMenu setSelectedCategory={setSelectedCategory} setFinalCategory={setFinalCategory} finalCategory={finalCategory} selectedCategory={selectedCategory} />
              {finalCategory === 1 ? <AynaCanvas /> : finalCategory === 2 ? <DolapCanvas /> : finalCategory === 3 ? <AynaliDolapCanvas /> : null}
              {/* <Message /> */}
            </>
          ) : (
            entegrationSelectedMenu
          )
        ) : null}
      </div>
    </ThemeProvider>
  );
};
